import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
    Box,
    Container,
    Stack,
    Heading,
    Text,
    Button,
    Image,
} from '@chakra-ui/react';
import logoRIP from '../assets/logoRIP.png';
import { useNavigate } from 'react-router-dom';



const Landing = () => {

    const navigate = useNavigate(); // Inicializa useNavigate

    const handleDownloadApp = () => {
        navigate('/app'); // Navega a la ruta '/app'
    };


    return (
        <ChakraProvider>
            <Box
                position="relative"
                bgGradient="linear(to-r, #248A3E, black)"
                color="white"
                minHeight="100vh"
            >
                <Container maxW="7xl" py={{ base: '16', md: '20' }}>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={{ base: 8, md: 10 }}
                        align="center"
                    >
                        <Stack
                            spacing={{ base: 8, md: 10 }}
                            flex="1"
                            textAlign={{ base: 'center', md: 'left' }}
                        >
                            <Heading
                                fontWeight="extrabold"
                                fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                                lineHeight="shorter"
                            >
                                RIP ALIVE (Descansa En Paz… Vivo)
                            </Heading>
                            <Text
                                fontSize={{ base: 'md', md: 'lg' }}
                                color="whiteAlpha.800"
                                maxW="3xl"
                            >
                                El QR con tu información vital al instante en caso de emergencia y tú le gado en caso de pérdida de la vida
                            </Text>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                spacing="4"
                                justify={{ base: 'center', md: 'flex-start' }}
                            >
                                <Button
                                    size="lg"
                                    bg="white"
                                    color="blue.500"
                                    _hover={{ bg: 'blue.100' }}
                                    onClick={handleDownloadApp}
                                >
                                    Descarga la aplicación
                                </Button>
                                <Button
                                    size="lg"
                                    variant="outline"
                                    borderColor="whiteAlpha.800"
                                    color="white"
                                    _hover={{ bg: 'whiteAlpha.300' }}
                                >
                                    Mas información
                                </Button>
                            </Stack>
                        </Stack>
                        <Box
                            flex="1"
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start" // Cambiado a flex-start para alinear arriba
                            mt={{ base: 0, md: 10 }} // Ajusta el margen superior según sea necesario
                        >
                            <Image
                                src={logoRIP}
                                alt="Product showcase"
                                borderRadius="lg"
                                shadow="lg"
                                maxH="300px"
                            />
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </ChakraProvider>
    );
};

export default Landing;
