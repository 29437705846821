//ContactStepComponent.jsx
import React, { useState } from 'react';
import { Box, Text, Button, HStack } from '@chakra-ui/react';
import locale from '../locale/locale.json';

function ContactStepComponent({ language, onYes, onNo }) {
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [locale.no[language], locale.yes[language]];

    const handleOptionClick = (option, index) => {
        setSelectedOption(option);
        if (index === 1) {
            onYes();
        } else {
            onNo();
        }
    };

    return (
        <Box>
            <Text>{locale.thanks[language]}</Text>
            <HStack spacing={2} mt={2}>
                {options.map((option, index) => (
                    <Button
                        key={option}
                        bg="#248A3E"
                        size="sm"
                        color="white"
                        isDisabled={!!selectedOption}
                        onClick={() => handleOptionClick(option, index)}
                    >
                        {option}
                    </Button>
                ))}
            </HStack>
        </Box>
    );
}

export default ContactStepComponent;
