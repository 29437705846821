import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Chat from './views/Chat';
import PatientVitals from './views/Information';
import './assets/fonts/vietnam-pro.css';
import Privacidad from './views/Privacidad';
import TerminosCondiciones from './views/TerminosCondiciones';
import AvisoDePrivacidad from './views/AvisoPrivacidad';
import DownloadAPP from './views/DownloadAPP';
import NoExiste from './views/NoExiste';
import Landing from './views/Landing';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Chat />} />
        <Route path="/no_existe" element={<NoExiste />} />
        <Route path="/information" element={<PatientVitals />} />
        <Route path="/:id" element={<Chat />} />
        <Route path="/privacidad" element={<Privacidad />} />
        <Route path="/tc" element={<TerminosCondiciones />} />
        <Route path="/aviso_privacidad" element={<AvisoDePrivacidad />} />
        <Route path="/Landing" element={<Landing />} />
        <Route path="/app" element={<DownloadAPP />} />
      </Routes>
    </Router>
  );
}

export default App;
