import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function ChatMessage({ message }) {

    console.log("mensaje", message);


    return (
        <Box>
            <Text>{message}</Text>
        </Box>
    );
}

export default ChatMessage;
