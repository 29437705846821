// Chat.js
import React, { useState, useEffect, useRef } from 'react';
import {
    ChakraProvider,
    Box,
    VStack,
    Text,
    Input,
    Button,
    extendTheme,
    HStack,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import MedicalCard from '../components/MedicalCard';
import locale from '../locale/locale.json';
import Language from '../components/Languages';
import GreetComponent from '../components/GreetComponent';
import RateEventComponent from '../components/RateEventComponent';
import IsContactedComponent from '../components/IsContactedComponent';
import EmergencyOptionsComponent from '../components/EmergencyOptionsComponent';
import UserMessage from '../components/UserMessage';
import ContactStepComponent from '../components/ContactStepComponent';
import ChatMessage from '../components/ChatMessage';

const ripGreen = "#248A3E";
const theme = extendTheme({
    components: {
        Input: {
            defaultProps: {
                focusBorderColor: ripGreen,
            },
        },
        Button: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none',
                },
            },
        },
    },
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Chat() {
    const query = useQuery();
    const id = query.get('id');
    const [userData, setUserData] = useState(null);
    const [userDataOriginal, setUserDataOriginal] = useState(null);
    const [componentsToRender, setComponentsToRender] = useState([]);
    const messagesEndRef = useRef(null);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [step, setStep] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [selectedRateEventOption, setSelectedRateEventOption] = useState(null);
    const [selectedIsContactedOption, setSelectedIsContactedOption] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [selectedEmergencyOption, setSelectedEmergencyOption] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [contactMessage, setContactMessage] = useState('');
    const inputRef = useRef(null);
    const [language, setLanguage] = useState('es');
    // eslint-disable-next-line no-unused-vars
    const [isLanguageSelected, setIsLanguageSelected] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (!id) {
                // Redirige si no hay id
                navigate('/no_existe');
                return;
            }
            setIsLoading(true);
            setError('');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/qr/${id}`);
                if (!response.data.data) {
                    // Redirige si no existe el usuario con el id proporcionado
                    navigate('/no_existe');
                } else {
                    setUserData({ ...response.data.data });
                    setUserDataOriginal({ ...response.data.data });
                }
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to load data.');
                setIsLoading(false);
                // Redirige si ocurre un error en la carga de datos
                navigate('/no_existe');
            }
        };

        fetchData();
    }, [id, navigate]);  // Asegúrate de agregar navigate como dependencia



    useEffect(() => {
        console.log("Step", step);
        if (step === 0 && userData) {
            const initialComponents = [
                <GreetComponent key={`greet-${language}`} userData={userData} language={language} />,
                <MedicalCard key={`patient-${language}`} patient={userData} language={language} />,
            ];
            setComponentsToRender(initialComponents);
            setStep(prevComponents => (prevComponents + 1));
        }
        if (step === 1 && componentsToRender.length === 2) {
            // Agrega el componente RateEvent
            setComponentsToRender(prev => [
                ...prev,
                <RateEventComponent
                    key={`rateEvent-${language}`}
                    userData={userData}
                    language={language}
                    onOptionSelected={(option, index) => {
                        setSelectedRateEventOption(option);
                        // Agrega el mensaje del usuario debajo del componente
                        setComponentsToRender(prevComponents => [
                            ...prevComponents,
                            <UserMessage key={`rateEventUserMessage-${prevComponents.length}`} message={option} />,
                        ]);
                        setStep(2);
                    }}
                />,
            ]);
        } else if (step === 2) {
            // Agrega el componente IsContacted
            setComponentsToRender(prev => [
                ...prev,
                <IsContactedComponent
                    key={`isContacted-${language}`}
                    language={language}
                    onOptionSelected={(option, index) => {
                        setSelectedIsContactedOption(option);
                        // Agrega el mensaje del usuario debajo del componente
                        setComponentsToRender(prevComponents => [
                            ...prevComponents,
                            <UserMessage key={`isContactedUserMessage-${prevComponents.length}`} message={option} />,
                        ]);
                        setStep(3);
                    }}
                />,
            ]);
        } else if (step === 3) {
            setComponentsToRender(prev => [
                ...prev,
                <EmergencyOptionsComponent
                    key={`emergencyOptions-${language}`}
                    language={language}
                    userSelectedYes={selectedIsContactedOption === locale.yes[language]}
                    onOptionSelected={(option) => {
                        setSelectedEmergencyOption(option);
                        // Agrega el mensaje del usuario debajo del componente
                        setComponentsToRender(prevComponents => [
                            ...prevComponents,
                            <UserMessage key={`emergencyOptionUserMessage-${prevComponents.length}`} message={option.name} />,
                        ]);
                        setStep(4);
                    }}
                />,
            ]);
        } else if (step === 4) {
            setComponentsToRender(prev => [
                ...prev,
                <ContactStepComponent
                    key={`contactStep-${language}`}
                    language={language}
                    onYes={() => {
                        handleResponseYes();
                        setComponentsToRender(prevComponents => [
                            ...prevComponents,
                            <UserMessage key={`responseYesUserMessage-${prevComponents.length}`} message={locale.yes[language]} />,
                            <ChatMessage key={`responseYesFollowup-${prevComponents.length + 1}`} message={locale.getContact[language]} />
                        ]);
                    }}
                    onNo={() => {
                        handleResponseNo();
                        setComponentsToRender(prevComponents => [
                            ...prevComponents,
                            <UserMessage key={`responseNoUserMessage-${prevComponents.length}`} message={locale.no[language]} />,
                            <ChatMessage key={`responseNoFollowup-${prevComponents.length + 1}`} message="Bien, gracias. Por favor, permanece en el chat si te es posible para informar a que sitio fue trasladado el usuario." />
                        ]);
                    }}
                />
            ]);
        } else if (step === 5) {


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, userData, language, selectedIsContactedOption]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [componentsToRender]);

    const handleResponseYes = () => {
        setStep(5);
    };

    const handleResponseNo = () => {
        setStep(6);
    };

    const handleSendMessage = () => {
        if (input.trim() !== '') {
            setComponentsToRender(prev => [
                ...prev,
                <UserMessage key={`userInput-${prev.length}`} message={input} />,
            ]);

            if (step === 5) {
                contacto(input);
                setComponentsToRender(prev => [
                    ...prev,
                    <UserMessage key={`contactSentMessage`} message={`Hemos enviado tu información. Gracias por ayudar a '${userData.name}'. Visita nuestras redes sociales para más información.`} />,
                ]);
                setStep(7);
            } else if (step === 6) {
                traslado(input);
            } else if (step >= 7) {
                adicional(input);
            }

            setInput('');
        }
    };

    const handleChangeLanguage = async (lang) => {
        setLanguage(prevComponents => lang);
        setIsLoading(true);
        setStep(prevComponents => 0);
        setComponentsToRender(prevComponents => []);

        const { gender, diseases, alergies, medications, alergiesIndications } = userDataOriginal;
        const body = { gender, diseases, alergies, medications, alergiesIndications, from: "es", to: lang };
        if (lang === "es") {
            setUserData({ ...userDataOriginal });
            setIsLoading(false);
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/utils/translate-json`, { data: body });
            updateUserData(userData, response.data);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setError('Failed to load data.');
            setIsLoading(false);
        }
    };

    function updateUserData(userdata, response) {
        const updateFields = response.data;
        const updatedUserData = { ...userdata };
        for (const key in updateFields) {
            if (updatedUserData.hasOwnProperty(key)) {
                updatedUserData[key] = updateFields[key];
            }
        }
        setUserData(updatedUserData);
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div style={{ color: 'red' }}>--- Mensaje de error --- {error}</div>;
    }

    if (!userData) {
        return <div>No data found.</div>;
    } else {

        return (
            <ChakraProvider theme={theme}>
                <Box
                    bg="gray.100"
                    pt={3}
                    pb={0}
                    px={4}
                    minH="100vh"
                    w={{ base: "100%", md: "375px" }}
                    mx={{ md: "auto" }}
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                >
                    <VStack
                        spacing={4}
                        overflowY="auto"
                        flex="1"
                        p={2}
                        style={{ maxHeight: '85vh' }}
                    >
                        <HStack mt='0'>
                            {locale.chose_language.map((lang, index) => (<Text key={index}>{lang}</Text>))}
                            <Language language={language} setLanguage={handleChangeLanguage} />
                        </HStack>

                        {componentsToRender.map((Component, index) => (
                            <Box
                                key={index}
                                alignSelf={Component.type === UserMessage ? 'flex-end' : 'flex-start'}
                                bg={Component.type === UserMessage ? ripGreen : 'white'}
                                p={3}
                                borderRadius="lg"
                            >
                                {Component}
                            </Box>
                        ))}

                        <div ref={messagesEndRef} />
                    </VStack>

                    {/* Oculta el input hasta que sea necesario */}
                    {step >= 4 && (
                        <HStack mt={4}>
                            <Input
                                placeholder={`${locale.write_here[language]} ...`}
                                flex="1"
                                value={input}
                                onChange={e => setInput(e.target.value)}
                                ref={inputRef}
                            />
                            <Button bg={ripGreen} color='white' onClick={handleSendMessage}>
                                {locale.send[language]}
                            </Button>
                        </HStack>
                    )}
                </Box>
            </ChakraProvider>
        );
    }
}

function contacto(data) {
    console.log('Enviando información de contacto:', data);
    // Implementar lógica para enviar información de contacto
}

function traslado(data) {
    console.log('Enviando información de traslado:', data);
    // Implementar lógica para manejar la información de traslado
}

function adicional(data) {
    console.log('Enviando información adicional:', data);
    // Implementar lógica para manejar información adicional
}

export default Chat;
